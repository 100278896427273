.card {
  display: inline-block;
  margin: px;
  box-sizing: border-box;
  overflow: hidden;
}

.card:hover {
  transform: translateY(-5px);
}

.card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card.sand {
  background-color: #f0e68c;
}

.card.blood {
  background-color: #cd5c5c;
}

.card.visible {
  opacity: 1;
}

.card.invisible {
  opacity: 0.5;
}

.card.empty {
  background-color: #ccc;
}

.card img {
  width: 100%;
  height: auto;
  display: block;
}

.card {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card img {
  height: 12vh;
  width: auto;
  object-fit: contain;
}

@media (max-width: 768px) {
  .other-players {
    grid-template-columns: 1fr;
  }

  .deck-piles {
    grid-template-columns: repeat(2, 1fr);
  }

  .deck-pile h4 {
    font-size: min(3vw, 0.9rem);
  }

  .player-hand h3 {
    font-size: min(4vw, 1rem);
  }

  .player-hand p {
    font-size: min(3.5vw, 0.9rem);
  }

  .deck-pile img,
  .card img {
    height: 10vh;
  }
}
