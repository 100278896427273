.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 2px solid #f0f0f0;
}

.modal-header h2 {
  color: #333;
  font-size: 24px;
  margin: 0;
}

.modal-close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
}

.modal-content {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  padding: 30px;
  font-family: "Roboto", sans-serif;
}

.modal-content h2 {
  color: var(--primary-color);
  margin-bottom: 20px;
}

.modal-content h3 {
  color: var(--secondary-color);
  margin-top: 20px;
  margin-bottom: 10px;
}

.modal-content ul {
  padding-left: 20px;
  margin-bottom: 15px;
}

.modal-content li {
  margin-bottom: 5px;
}

.modal-content p {
  margin-bottom: 15px;
}

.modal-content button {
  margin-top: 20px;
}

.modal-body {
  color: #444;
  font-size: 16px;
  line-height: 1.6;
}

.modal-body h3 {
  color: #2c3e50;
  font-size: 20px;
  margin-top: 25px;
  margin-bottom: 15px;
}

.modal-body ul {
  padding-left: 20px;
  margin-bottom: 20px;
}

.modal-body li {
  margin-bottom: 10px;
}

.modal-body p {
  margin-bottom: 15px;
}

.modal-content::-webkit-scrollbar {
  width: 8px;
}

.modal-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.modal-content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.modal-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.modal-content button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.modal-content button:hover {
  background-color: var(--secondary-color);
}
