.player-hand {
  background-color: var(--card-background);
  border-radius: var(--border-radius);
  padding: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  transition: all 0.3s ease;
  box-sizing: border-box;
}

.player-hand.current {
  border: 3px solid var(--primary-color);
  transform: scale(1.05);
}

.cards {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 15px;
}

.player-info {
  flex-grow: 1;
  margin-right: 20px;
}

.player-info h3 {
  font-size: 1.2rem;
}

.player-info p {
  font-size: 1rem;
}

.actions {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  margin-left: 20px;
}

.actions select {
  margin-bottom: 5px;
  padding: 5px;
  width: 100%;
}

.actions button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin: 5px;
  font-weight: bold;
  margin-top: 10px;
  width: 100%;
}

.actions button:hover {
  background-color: #2980b9;
  transform: scale(1.05);
}

.actions button:active {
  transform: scale(0.95);
}

@media (max-width: 768px) {
  .player-hand {
    width: 100%;
    transition: all 0.3s ease;
  }
}

.player-hand {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1vh;
}

.player-hand .cards {
  display: flex;
  gap: 1vw;
  justify-content: center;
  align-items: center;
}

.player-hand .cards img {
  height: 12vh;
  width: auto;
  object-fit: contain;
}

.player-hand h3 {
  font-size: min(1.5vw, 1.2rem);
  margin-bottom: 0.5vh;
}

.player-hand p {
  font-size: min(1.2vw, 1rem);
}
